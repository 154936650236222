import './ContentManager.css'
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { fetchUploadedFiles, fetchGeneratedFiles, getJSONFormat, getReprocessedJSON, getCompleteJson, saveJson, getGeneratedJson, activateDocuments } from '../../services/FileService';
import { useProfile } from '../../contexts/ProfileContextType';
import { GeneratedFileDTO } from '../../types/GeneratedFileDTO';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BiShow } from "react-icons/bi"
import Loading from '../utils/Loading';
import Modal from '../modals/JsonViewer';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import { saveProfile } from '../../services/ProfileService';
import { Int } from '@uiw/react-json-view/cjs/types/Int';


/**
 * ContentManager Component
 * 
 * This component manages the content of uploaded and generated files, allowing users to
 * upload, process, view, and activate documents.
 * 
 * Functions using this component:
 * - None (This is a top-level component)
 * 
 * Functions used by this component:
 * - useProfile (custom hook)
 * - fetchUploadedFiles, fetchGeneratedFiles, getJSONFormat, getCompleteJson, saveJson, getGeneratedJson, activateDocuments (from FileService)
 * - saveProfile (from ProfileService)
 * - toast (from react-toastify)
 */
const ContentManager: React.FC = () => {
    const { selectedProfileId, setPdfFormat, pdfFormat,profileName } = useProfile();
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [generatedFiles, setGeneratedFiles] = useState<GeneratedFileDTO[]>([]);
    const [selectedGeneratedFiles, setSelectedGeneratedFiles] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [json, setJson] = useState<any>(null);
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: { checked: boolean, disabled:boolean, text: string, originalKey: string[]} }>({});
    const [fetchedJsonFileName, setFetchJsonFileName] = useState<string>('');
    const [fetchJsonFileTitle, setFetchJsonFileTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState({});
    const [isJsonModalVisible, setIsJsonModalVisible] = useState(false);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [deletedFile, setDeletedFile] = useState<string>('');
    const [isUploadedFilesLoading, setIsUploadedFilesLoading] = useState(true);
    const [smartProcessing, setSmartProcessing] = useState(false);
    const [fileToReprocess, setFileToReprocess] = useState<File | null>(null);
    const [clickedFile, setClickedFile] = useState(String);
    
    

    /**
     * Effect hook to fetch uploaded and generated files when the selected profile changes
     * 
     * Functions using this effect:
     * - None
     * 
     * Functions used by this effect:
     * - fetchUploadedFiles, fetchGeneratedFiles
     */
    useEffect(() => {
        if (selectedProfileId === 'select-profile') return;
        setIsUploadedFilesLoading(true);
        fetchUploadedFiles(selectedProfileId)
            .then((data) => { setUploadedFiles(data.files); })
            .catch((error) => {
                toast.error('Failed to fetch uploaded files');
            })
            .finally(() => setIsUploadedFilesLoading(false));
        fetchGeneratedFiles(selectedProfileId)
            .then((data) => {
                setGeneratedFiles(data.files);
                setSelectedGeneratedFiles(data.files.filter((file: { activated: any; }) => file.activated).map((file: { file: any; }) => file.file));
            })
            .catch((error) => {
                toast.error('Failed to fetch generated files');
            });
    }, [selectedProfileId]);

    /**
     * Effect hook to initialize checked state when JSON data is available
     * 
     * Functions using this effect:
     * - None
     * 
     * Functions used by this effect:
     * - setCheckedItems, setFetchJsonFileTitle
     */
    useEffect(() => {
        if (json) {
            const initializeCheckedState = (data: any, parentKey = '') => {
                const entries: { [key: string]: { checked: boolean, disabled: boolean, text: string, originalKey: string[]} } = {};

                // Handle DocumentTitle separately
                if (data[0] instanceof Array) {
                    entries['DocumentTitle'] = { checked: true, disabled: true, text: data[0][0].DocumentTitle, originalKey:['Document Title']};
                    setFetchJsonFileTitle(data[0][0].DocumentTitle);
                } else {
                    entries['DocumentTitle'] = { checked: true, disabled: true, text: data[0].DocumentTitle, originalKey:['Document Title'] };
                    setFetchJsonFileTitle(data[0].DocumentTitle);
                }

                // Recursive function to add entries for each key
                const addEntries = (obj: object, path: string, originalKeyPath: string[]) => {
                    Object.entries(obj).forEach(([key, value], index) => {
                        if (key === 'DocumentTitle') return;
                        let fullKey = path;
                        let originalKey = originalKeyPath;
                        // Skip 'list' and 'table' keys
                        if (key.toLowerCase() !== 'list' && key.toLowerCase() !== 'table')
                        {
                            // Check if the value is an array and the current item is an object
                            // console.debug(`=> Check invalid ARRAYS @key=${key} @value=${value} @index=${index} @Array.isArray(value)=${Array.isArray(value)} typeof value=${typeof value}`);
                            //!!! json parsing starts as record is an ARRAY - might be a problem if the input IS an Array - to be checked if required
                            if (key==='0' && typeof value === 'object' && value !== null && path === '') {
                                // Process object in the array without adding this level to the key
                                addEntries(value, fullKey, originalKeyPath);
                                return;
                            }
                            // Use generateUniqueKey for consistent key generation
                            fullKey = generateUniqueKey(path, key, index);
                            originalKey = [...originalKeyPath, key];
                            const isDescription = fullKey.toLowerCase().includes('description');

                            // Entry initialization: mark as checked based on key, or empty text for top-level entries
                            entries[fullKey] = {
                                checked: isDescription,
                                disabled: isDescription,
                                text: typeof value === 'object' ? "" : value, //isTopLevel && !isDescription 
                                originalKey: originalKey
                            };
                            // console.debug(`=> initializeCheckedState @checked=${isDescription}\n - fullKey=${fullKey}\n - label=${entries[fullKey].text}`);
                        }
                        // Recurse into nested objects
                        if (typeof value === 'object' && value !== null) {
                            addEntries(value, fullKey, originalKey);
                        }
                    });
                };

                addEntries(data, parentKey, []);
                setCheckedItems(entries);
            };

            initializeCheckedState(json);
        }
    }, [json]);

    /**
     * Effect hook to handle the case when there are no uploaded files
     * 
     * Functions using this effect:
     * - None
     * 
     * Functions used by this effect:
     * - setPdfFormat, saveProfile, toast
     */
    useEffect(() => {
        if (!isUploadedFilesLoading && uploadedFiles.length === 0) {
            toast.info('No uploaded files. Please select a PDF type and then save.');
            setPdfFormat('select-style');
            saveProfile(selectedProfileId, profileName, 'select-style');

            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [uploadedFiles]);

    /**
     * Handles file upload and processing
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - getJSONFormat
     * - fetchCompleteJson
     * - fetchFileLists
     * - toast.success, toast.error
     * - setLoading
     */
    const handleFileUpload = async () => {
        if (!selectedFile) {
            toast.error("Please select a file first.");
            return;
        }
        try {
            setLoading(true);
            // Pass the smartProcessing value as part of the form data
            const data = await getJSONFormat(selectedProfileId, selectedFile, smartProcessing);  
            toast.success(`${selectedFile.name} processed successfully!`);
            fetchCompleteJson(selectedFile.name);
            fetchFileLists();
        } catch (error) {
            toast.error('Failed to process file');
        } finally {
            setLoading(false);
        }
      
    };



    /**
     * Fetches the list of uploaded and generated files
     * 
     * Functions using this function:
     * - handleFileUpload
     * - handleSave
     * - handleActivate
     * 
     * Functions used by this function:
     * - fetchUploadedFiles, fetchGeneratedFiles
     * - toast.error
     * - setUploadedFiles, setGeneratedFiles, setSelectedGeneratedFiles
     */
    const fetchFileLists = async () => {
        fetchUploadedFiles(selectedProfileId)
            .then((data) => setUploadedFiles(data.files))
            .catch((error) => {
                toast.error('Failed to fetch uploaded files');
            })
        fetchGeneratedFiles(selectedProfileId)
            .then((data) => {
                setGeneratedFiles(data.files);
                setSelectedGeneratedFiles(data.files.filter((file: { activated: any; }) => file.activated).map((file: { file: any; }) => file.file));
            })
            .catch((error) => {
                toast.error('Failed to fetch generated files');
            });
    }


    /**
     * Toggles the selection of a generated file
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - setSelectedGeneratedFiles
     */
    const toggleFileSelection = (file: GeneratedFileDTO) => {
        setSelectedGeneratedFiles((prevSelected) => {
            if (prevSelected.includes(file.file)) {
                // If file is already selected, remove it from the selection
                return prevSelected.filter(f => f !== file.file);
            } else {
                // File is not selected, add it to the selection
                return [...prevSelected, file.file];
            }
        });
    };

    /**
     * Fetches the complete JSON for a given filename
     * 
     * Functions using this function:
     * - handleFileUpload
     * 
     * Functions used by this function:
     * - getCompleteJson
     * - setFetchJsonFileName, setJson
     * - toast.error
     */
    const fetchCompleteJson = async (filename: string) => {
        try {
            filename = filename.split('.').slice(0, -1).join('.');
            setFetchJsonFileName(filename);
            const data = await getCompleteJson(selectedProfileId, filename);
            setJson(data);
            const element = document.getElementById('input-manager');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return data;
        } catch (error) {
            toast.error('Failed to fetch complete JSON');
            throw error;
        }
    };

    const getFileByTitle = async (id: string, title: string) => {
        try {
            const response = await fetchUploadedFiles(id);
            // Access the 'files' property from the response
            const files = response.files;
            
            // Ensure 'files' is an array and find the file by title
            if (Array.isArray(files)) {
                const file = files.find((file: string) => file === title);
                return file;
            } else {
                console.error('Expected an array of files but got:', files);
                return null;
            }
        } catch (error) {
            console.error('Failed to get file by title:', error);
            throw error;
        }
    }
    

    /**
     * Handles saving the selected JSON content
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - saveJson
     * - fetchFileLists
     * - toast.success, toast.error
     * - setLoading
     */
    const handleSave = () => {
        const constructJsonFromCheckedItems = () => {
            const result = {};

            // Recursive function to construct JSON based on checked items
            const assignValueToPath = (originalKeys: string[], value: string, obj: any) => {
                // const keys = path.split('.');
                let current = obj;

                // Traverse or create objects along the path
                for (let i = 0; i < originalKeys.length - 1; i++) {
                    const key = originalKeys[i];
                    console.debug(`Work with the key segments=${key}\n - current[key]=${current[key]}- typeof current[key]=${typeof current[key]}`);
                    // If the key doesn't exist or isn't an object, create/overwrite it
                    if (!current[key] || typeof current[key] !== 'object') {
                        current[key] = {};
                    }
                    current = current[key];
                }

                // Assign the value to the final key
                current[originalKeys[originalKeys.length - 1]] = value;
            };

            console.debug('Checked Items:', checkedItems);

            // Filter checked items and reconstruct the JSON structure
            Object.entries(checkedItems).forEach(([key, { checked, text, originalKey }]) => {
                if (checked && key !== 'DocumentTitle') {
                    console.debug(`Checked Items filtered: text=${text}\n - key=${key}\n - originalKeys=${originalKey}`);
                    assignValueToPath(originalKey, text, result);
                }
            });

            return {
                DocumentTitle: fetchJsonFileTitle,
                Content: result
            };
        };

        const savedJson = constructJsonFromCheckedItems();
        setLoading(true);
        saveJson(selectedProfileId, fetchedJsonFileName, savedJson).then(() => {
            fetchFileLists();
            toast.success('Selections saved successfully!');
            setTimeout(() => {
                const element = document.getElementById('activate-documents');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
        }).catch((error) => {
            toast.error('Failed to save selections');
        }).finally(() => setLoading(false));
    };

    /**
     * Handles activation of selected documents
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - activateDocuments
     * - fetchFileLists
     * - toast.success, toast.error, toast.info
     * - setLoading
     */
    const handleActivate = () => {
        if(pdfFormat === 'select-style') {
            toast.info('Please select a profile style first and save it!');
            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return;
        }
        if (selectedGeneratedFiles.length === 0) {
            toast.info('Please select at least one document to activate');
            return;
        }
        setLoading(true);
        activateDocuments(selectedProfileId, selectedGeneratedFiles, pdfFormat).then(() => {
            fetchFileLists();
            setTimeout(() => {
                const appElement = document.getElementById('app');
                if (appElement) {
                    appElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
            toast.success('Documents activated successfully!');
        }).catch((error) => {
            toast.error('Failed to activate documents');
        }).
            finally(() => setLoading(false));
    };

    /**
     * Handles checkbox state changes
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - setCheckedItems
     */
    const handleCheckboxChange = (fullKey: string, isChecked: boolean) => {
        setCheckedItems(prev => {
            const updated = { ...prev };
            // console.debug(`===================== 'handleCheckboxChange' called ==============`);
    
            const updateItemAndDescendants = (itemKey: string, checkState: boolean) => {
                if (!updated[itemKey]) {
                    console.debug(`!!!!!!!!!!!!!!!!!!! DID NOT FIND: 'updated[itemKey]' = ${itemKey}`);
                    updated[itemKey] = { checked: false, disabled: false, text: '', originalKey: ['']}; //TODO originalKey to fix (?)
                }
                updated[itemKey].checked = checkState;
                // console.debug(`Updating child ${itemKey} to ${checkState}`); // Debugging: Log child updates
    
                // Update descendants
                // console.debug(`=> the list of childerns to update: ${Object.keys(prev)}`);
                Object.keys(prev).forEach(key => {
                    if (key.startsWith(`${itemKey}.`) || key.startsWith(`${itemKey}.[`)) {
                        if (!updated[key]) {
                            console.debug(`!!!!!!!!!!!!!!!!!!! DID NOT FIND <child>: 'updated[key]' = ${key}`);
                            updated[key] = { checked: false, disabled: false, text: '', originalKey: [''] }; //TODO originalKey to fix (?)
                        }
                        updated[key].checked = checkState;
                    }
                });
            };
    
            const updateAncestors = (childKey: string) => {
                const segments = childKey.split('.');
                // console.debug(`===> parent segments: ${segments}; length: ${segments.length}`); // Debugging: Log parent updates
                while (segments.length > 1) {
                    segments.pop();
                    const parentKey = segments.join('.');
                    const childrenKeys = Object.keys(updated).filter(key => 
                        (key.startsWith(`${parentKey}.`) || key.startsWith(`${parentKey}[`)) && 
                        key !== parentKey && 
                        !key.toLowerCase().includes('description') // Exclude keys that include 'description'                    
                    );
                    const allChildrenChecked = childrenKeys.length > 0 && 
                        childrenKeys.some(key => updated[key]?.checked);
                    console.debug(`Current parent's=${parentKey}\n - Children's keys ${childrenKeys}\n - allChildrenChecked ${allChildrenChecked}`);
    
                    if (!updated[parentKey]) {
                        console.debug(`!!!!!!!!!!!!!!!!!!! DID NOT FIND <parent>: 'updated[parentKey]' = ${parentKey}`);
                        updated[parentKey] = { checked: false, disabled: false, text: '', originalKey: [''] }; //TODO originalKey to fix (?)
                        // console.debug(`Updating parent ${parentKey} to ${false}`); // Debugging: Log parent updates
                    }
                    updated[parentKey].checked = allChildrenChecked;
                    console.debug(`Updating parent ${parentKey} to ${allChildrenChecked}`); // Debugging: Log parent updates
    
                    // if (!allChildrenChecked) break; // Stop if not all children are checked
                }
            };
    
            // Update the clicked item and its descendants
            updateItemAndDescendants(fullKey, isChecked);
    
            // Update ancestors
            updateAncestors(fullKey);
    
            return updated;
        });
    };

    /**
     * Generates a unique key for checkbox-tree elements
     * 
     * Functions using this function:
     * - createCheckboxes
     * 
     * Functions used by this function:
     * - None
     */
    const generateUniqueKey = (parentKey: string, key: any, index: any) => {
        if (key === undefined) {
            if (!parentKey) console.log('!!!!!!!!!!!!!! SHOULD NOT HAVE ARRAY IN TOP LEVEL !!!!');
            // For list or array items without a key
            return `${parentKey ? parentKey + '.' : ''}${index}`;  // Should not start with Array index...
        }

        // Convert key to a string and replace special characters, including '.'
        const sanitizedKey = key.toString().replace(/[^a-zA-Z0-9_-]/g, '');

        return `${parentKey ? parentKey + '.' : ''}${sanitizedKey}`;
    };

    /**
     * Added new nested processing of Arrays in the json input for createCheckboxes
     * 
     * Functions using this function:
     * - createCheckboxes
     * 
     * Functions used by this function:
     * - generateUniqueKey
     */
    const processArrayItems = (
        value: any[],
        level: number,
        parentKey: string,
        isUnderDescription: boolean,
        itemCount: number
    ): JSX.Element[] => {
        return value.flatMap((item, itemIndex) => {
            let childCount;
    
            // Determine the structure of the item (array, object, or single key-value pair)
            if (Array.isArray(item)) {
                childCount = item.length;
            } else if (typeof item === 'object' && item !== null) {
                childCount = Object.keys(item).length;
            } else {
                childCount = 1;
            }
    
            // console.debug(`==> This child='''${JSON.stringify(item)}''' in the Array contains=${childCount} items at the current level=${level}`);
            const fullKey = generateUniqueKey(parentKey, undefined, itemIndex);
            const content: JSX.Element[] = [
                ...createCheckboxes(item, level, fullKey, isUnderDescription) //, true)
            ];
    
            // Insert <hr> between objects, but not after the last one
            if (itemIndex < itemCount - 1 && childCount > 1) {
                content.push(<hr className="my-2 border-t-8 border-gray-300" />); //key={`divider-${itemIndex}`} 
            }
    
            return content;
        });
    };
    
    /**
     * Creates checkbox elements recursively from JSON data
     * 
     * Functions using this function:
     * - ContentManager component (in JSX)
     * 
     * Functions used by this function:
     * - generateUniqueKey
     * - handleCheckboxChange
     */
    const createCheckboxes = (data: any, level = 0, parentKey = '', isUnderDescription = false): JSX.Element[] => {
        if (Array.isArray(data)) {
            return data.flatMap((item, index) => {
                if (typeof item === 'object' && item !== null) {
                    // Skip processing this level if the item is an object
                    return createCheckboxes(item, level, parentKey, isUnderDescription);
                } else {
                    const fullKey = generateUniqueKey(parentKey, undefined, index);
                    // console.debug(`Array detected - key ${fullKey} - for level ${level}`);
                    // console.debug(`parentKey ${parentKey} - item ${item} - index ${index}`);
                    return createCheckboxesForItem(item, level, fullKey, isUnderDescription);
                }
            });
        } else if (typeof data === 'object' && data !== null) {
            return Object.entries(data).flatMap(([key, value], index) => {
                if (key === 'DocumentTitle') return [];
    
                if (Array.isArray(value)) {
                    // console.debug(`List key detected - grouping items under parent key "${parentKey}"`);
                    const itemCount = value.length;
                    // console.debug(`==> Array contains ${itemCount} items at the current level`);
            
                    // Graphical grouping for list elements, without adding "List" checkbox
                    if (key.toLowerCase() === 'list' || key.toLowerCase() === 'table') {
                        // console.debug(`The Array is a List/Table key detected - grouping items under parent key "${parentKey}"`);
                        return (
                            <div key={`group-${parentKey}-${index}`} className={`ml-${level * 5} border-l-2 border-gray-300 pl-4`}>
                                {
                                // createCheckboxes(value, level, parentKey, isUnderDescription, true)
                                processArrayItems(value, level, parentKey, isUnderDescription, itemCount)
                                }
                            </div>
                        );
                    } else {
                        const fullKey = generateUniqueKey(parentKey, key, null);
                        const isDescription = key?.toLowerCase().includes('description') || isUnderDescription;
                        // console.debug(`ARRAY item detected in - fullkey ${fullKey} - for level ${level}`);
                        // console.debug(`parentKey ${parentKey} - key ${key} - value ${value}`);
                        // Process each item in the array
                        return ([createCheckbox(fullKey, key, level, isDescription),
                            <div key={`group-${parentKey}-${index}`} className={`ml-${(level+1) * 5} border-l-2 border-gray-300 pl-4`}>
                                {processArrayItems(value, level + 1, fullKey, isDescription, itemCount)}
                            </div>]
                        );
                     }
                } else {
                    const fullKey = generateUniqueKey(parentKey, key, null);
                    // console.debug(`Object detected - key ${fullKey} - for level ${level}`);
                    // console.debug(`parentKey ${parentKey} - key ${key} - value ${value}`);
                    return createCheckboxesForItem(value, level, fullKey, isUnderDescription, key);
                }
            });
        } else {
            return createCheckboxesForItem(String(data), level, parentKey, isUnderDescription);
            // return [createCheckbox(parentKey, String(data), level, isUnderDescription)];
        }
    };

    /** Extracted from createCheckboxes */
    const createCheckboxesForItem = (item: any, level: number, fullKey: string, isUnderDescription: boolean, key?: string): JSX.Element[] => {
        const isDescription = key?.toLowerCase().includes('description') || 
            (typeof item === 'string' && item.toLowerCase().includes('description'));
        const disableChildren = isDescription || isUnderDescription;
    
        if (typeof item === 'object' && item !== null) {
            return [   createCheckbox(fullKey, key || 'Item', level, disableChildren),
                    ...createCheckboxes(item, level + 1, fullKey, disableChildren)];
        } else {
            const label = key ? `${key}: ${item}` : String(item);
            return [createCheckbox(fullKey, label, level, disableChildren)];
        }
    };
    
    /** Extracted from createCheckboxes */
    const createCheckbox = (fullKey: string, label: string, level: number, isDisabled: boolean): JSX.Element => {
        // console.debug(`=> createCheckbox @level=${level} isDisabled=${isDisabled} isChecked=${checkedItems[fullKey]?.checked}\n - fullKey=${fullKey}\n - label=${label}`);
        return (
            <div key={fullKey} className={`ml-${level * 5} mt-2`} style={{ marginLeft: `${level * 10}px` }}>
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:bg-gray-200"
                        checked={!!checkedItems[fullKey]?.checked || isDisabled}
                        onChange={(e) => handleCheckboxChange(fullKey, e.target.checked)}
                        disabled={isDisabled}
                    />
                    <span className={`text-sm ${isDisabled ? 'text-gray-500' : 'text-gray-700'}`}>{label as React.ReactNode}</span>
                </label>
            </div>
        );
    };

    /**
     * Handles selecting all checkboxes
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - setCheckedItems
     */
    const handleSelectAll = () => {
        const updated = { ...checkedItems };
        Object.keys(updated).forEach(key => {
            updated[key] = { checked: true, disabled:updated[key].disabled , text: updated[key].text, originalKey: updated[key].originalKey };
        });
        setCheckedItems(updated);
    };

    const handleDeselectAll = () => {
        const updated = { ...checkedItems };
        Object.keys(updated).forEach(key => {
            // Only update if the checkbox is not disabled
            if (!checkedItems[key].disabled) {
                updated[key] = { checked: false, disabled:updated[key].disabled, text: updated[key].text, originalKey: updated[key].originalKey};
            }
        });
        setCheckedItems(updated);
    };

    const handleReprocess = async (file: string) => {
        const reprocessFile = await getFileByTitle(selectedProfileId, file)
        // FIXME: reprocessFile is undefined, because file has no extension.
        // fetchedJsonFileName has no extension. Why? can we figure extension out? can we include it in fetchedJsonFileName?
        // maybe we can get the file name from different source? create another state for fetchedJsonFileNameWithExtension?
        if (reprocessFile === undefined){
            toast.error(`File not found! File: ${file}. Extension is missing?`);
            return
        }

        setFileToReprocess(reprocessFile)
        try {
            setLoading(true);
            await getReprocessedJSON(selectedProfileId, reprocessFile, smartProcessing);  
            toast.success(`${reprocessFile} reprocessed successfully!`);
            fetchCompleteJson(reprocessFile);
            fetchFileLists();
        } catch (error) {
            toast.error(`Failed to process file ${file}, because ${error}`);
        } finally {
            setLoading(false);
        }
    }
    
    /**
     * Fetches and displays the generated JSON for a file
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - getGeneratedJson
     * - setModalContent, setIsJsonModalVisible
     * - toast.error
     */
    const handleGetGeneratedJson = async (filename: string) => {
        try {
            const data = await getGeneratedJson(selectedProfileId, filename);
            data.file_content = JSON.parse(data.file_content);
            setModalContent(data.file_content);
            setIsJsonModalVisible(true);
        } catch (error) {
            toast.error('Failed to fetch generated JSON');
        }
    };

    /**
     * Handles file deletion confirmation
     * 
     * Functions using this function:
     * - None (Called by event handler)
     * 
     * Functions used by this function:
     * - setDeletedFile, setIsDeleteConfirmationVisible
     */
    const handleFileDeletion = (file: string) => {
        setDeletedFile(file);
        setIsDeleteConfirmationVisible(true);
    }

    

    if (loading) {
        return <Loading />;
    }

    // Render JSX
    // ... (JSX implementation)
    return (
        <div className="max-w-4xl mx-auto my-10">
            {/* Combined Step 3 and Step 5 in a grid layout */}
            <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500 md:grid md:grid-cols-2 md:gap-6">
                {/* Document Management - Step 3 */}
                <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500">
                    <h3 className="text-lg font-semibold mb-4">Step 3: Document Management</h3>
                    <div className="space-y-2 mb-2">
                    {uploadedFiles.map((file, index) => (
                            <div key={index} className={`flex justify-between items-center p-2 rounded-lg ${clickedFile === file ? 'highlighted-div' : 'bg-gray-100'}`}>
                                <button 
                                    onClick={() => {
                                        fetchCompleteJson(file);// Pass filename for processing
                                        setClickedFile(file);
                                    }} 
                                    className= "text-sm flex-1 text-left truncate-button"
                                    title={file} // Tooltip for showing the full filename
                                >
                                    {file} {/* Display filename */}
                                </button>
                                <RiDeleteBin6Line onClick={() => handleFileDeletion(file)} className="cursor-pointer text-red-500 ml-4" />
                            </div>
                        ))}
                    </div>
                    <DeleteConfirmation profileId={selectedProfileId} file={deletedFile} fetchFileLists={fetchFileLists} isVisible={isDeleteConfirmationVisible} onClose={() => setIsDeleteConfirmationVisible(false)} />
                    <input type="file" onChange={e => setSelectedFile(e.target.files ? e.target.files[0] : null)} className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100" />
                    <div className="mb-4">
                        <label className="flex items-center space-x-3">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                checked={smartProcessing} 
                                onChange={(e) => setSmartProcessing(e.target.checked)} 
                            />
                            <span className="text-sm">Smart PDF Processing</span>
                        </label>
                    </div>
                    <button onClick={handleFileUpload} className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        id="document-management"
                    >
                        Upload and Process File
                    </button>
                    <button
                       style={{marginLeft : '10px'}}
                        // For now: reprocess the file displayed at "Step 4: Managing Content in Form Inputs".
                        // TODO: disable button when no file selected that could be reprocessed 
                        onClick={() => handleReprocess(fetchedJsonFileName)}
                        className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Reprocess File
            </button>
                </div>

                {/* Activate Selected Forms - Step 5 */}
                <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500">
                    <h3 className="text-lg font-semibold mb-4" id="activate-documents">Step 5: Activate Selected Forms</h3>
                    <div className="space-y-2">
                        {generatedFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded-lg">
                                <input type="checkbox" checked={selectedGeneratedFiles.includes(file.file)} onChange={() => toggleFileSelection(file)} className="mr-2" />
                                <BiShow onClick={() => handleGetGeneratedJson(file.file)} className="cursor-pointer text-blue-500 mr-2" />
                                <Modal
                                    content={modalContent}
                                    isVisible={isJsonModalVisible}
                                    onClose={() => setIsJsonModalVisible(false)}
                                />
                                <span className="text-sm flex-1 text-left">{file.file}</span>
                            </div>
                        ))}
                    </div>
                    <button onClick={handleActivate} className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Activate Documents
                    </button>
                </div>
            </div>

            {/* Managing Content in Form Inputs - Step 4 */}
            <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500 mt-10">
                <h3 className="text-lg font-semibold mb-4">Step 4: Managing Content in Form Inputs</h3>
                <div className="gap-4 mb-4 flex items-center">
                    <button onClick={handleSelectAll} className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                        Select all
                    </button>
                    <button onClick={handleDeselectAll} className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-700 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400">
                        Deselect all
                    </button>
                    <button onClick={handleSave} className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    id="input-manager">
                        Save Selections
                    </button>
                </div>
                <h4 className="text-lg font-semibold mb-4">{fetchJsonFileTitle}</h4>
                <div id="checkbox-container">
                    {json && createCheckboxes(json)}
                </div>
            </div>
        </div>
    );
};

export default ContentManager;
