import axios from 'axios';

const API_BASE_URL="https://beta.askqua.bf-ai.com/"

axios.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('token');
      if (token && config.url != `${API_BASE_URL}/login`) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  (error) => {
      console.error('Failed to set token:', error);
      return Promise.reject(error);
  }
);

axios.interceptors.response.use(response => response, error => {
  if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      if(window.location.pathname !== '/login') window.location.reload();
  }
  return Promise.reject(error);
}); 

const fetchProfiles = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-profiles-web`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch profiles:', error);
    throw error;
  }
};

const fetchAllProfiles = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-profiles`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch profiles:', error);
    throw error;
  }
};

const fetchProfileLogin = async (username : string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-profile-login`, { params: { username } });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch profiles:', error);
    throw error;
  }
};

const saveProfile = async (id:string, saved_name:string, baseline:string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/update-profile?profile_index=${id}`, {
        saved_name,
        baseline,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to save profile:', error);
    throw error;
  }
};

const getProfileParameters = async (id:string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get_profile_parameters?profile_index=${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch profile parameters:', error);
        throw error;
    }
}

const getProfileParametersLogin = async (id:string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get_profile_parameters_login?profile_index=${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch profile parameters:', error);
        throw error;
    }
}

const saveProfileParameters = async (id:string, company_details:string, assistant_position:string, assistant_description:string, information:string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/save-parameters?profile_index=${id}`, {
            company_details,
            assistant_position,
            assistant_description,
            information,
        });
        return response.data;
    } catch (error) {
        console.error('Failed to save profile parameters:', error);
        throw error;
    }
}

const getDefaultParameters = async (baseline:string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get_default_profile_parameters?baseline=${baseline}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch default parameters:', error);
        throw error;
    }
}

export { 
  fetchProfiles,
  saveProfile,
  getProfileParameters,
  saveProfileParameters,
  getDefaultParameters,
  fetchProfileLogin,
  getProfileParametersLogin,
  fetchAllProfiles
};
