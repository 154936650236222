import axios from 'axios';

const API_BASE_URL="https://beta.askqua.bf-ai.com/"


axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token && config.url != `${API_BASE_URL}/login`) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('Failed to set token:', error);
        return Promise.reject(error);
    }
  );

axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if(window.location.pathname !== '/login') window.location.reload();
    }
    return Promise.reject(error);
}); 

const login = async (username: string, password: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, {
            username,
            password,
        });
        localStorage.setItem('token', response.data.access_token);
        return response.data;
    } catch (error) {
        console.error('Failed to login:', error);
        throw error;
    }
};

const logout = () => {
    localStorage.removeItem('token');
}

const changePassword = async (username: string, password: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/change-password`, {
            username,
            password,
        });
        return response.data;
    } catch (error) {
        console.error('Failed to change password:', error);
        throw error;
    }
}

const getUsers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/users`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch users:', error);
        throw error;
    }
}

const resetPassword = async (username: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/reset-password`, {
            username,
        });
        return response.data;
    } catch (error) {
        console.error('Failed to reset password:', error);
        throw error;
    }
}

export { 
    login,
    logout,
    changePassword,
    getUsers,
    resetPassword,
};